import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import { declareLazyRouterModule } from "./utils/routing/routeCreation";

const router = createBrowserRouter([
    { element: <App />, children: [
        /** Global pages/routes, not specific to any module */
        declareLazyRouterModule(false, lazy(() => import('./modules/RootModule/RootModule'))),

        /** The different application modules */
        declareLazyRouterModule('demo', lazy(() => import('./modules/DemoModule/DemoModule'))),
        declareLazyRouterModule('admin', lazy(() => import('./modules/AdminModule/AdminModule'))),
        declareLazyRouterModule('trainer', lazy(() => import('./modules/OrganisationModule/OrganisationModule'))),
        declareLazyRouterModule('infographic', lazy(() => import('./modules/InfographicModule/InfographicModule'))),
        declareLazyRouterModule('dashboards', lazy(() => import('./modules/DashboardModule/DashboardModule'))),

        declareLazyRouterModule('survey', lazy(() => import('./modules/SurveyModule/SurveyModule'))),
        declareLazyRouterModule('learning', lazy(() => import('./modules/SurveyModule/SurveyModule'))),
        declareLazyRouterModule('forms', lazy(() => import('./modules/SurveyModule/SurveyModule'))),

    ]}
]);

export default router;
