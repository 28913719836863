import { serialize } from 'object-to-formdata';
import FileSaver from 'file-saver';
import { skipToken as rtkSkipToken } from '@reduxjs/toolkit/dist/query';
import { platformBffApiGenerated } from './generated/platformBffApi';
import { inheritQuery } from './inheritQuery';

export * from './generated/platformBffApi';
export const skipToken: any = rtkSkipToken;

const ignoreTags = (endpoint: any) => {
  endpoint.providesTags = [];
};

export const platformBffApi = platformBffApiGenerated.enhanceEndpoints({
  endpoints: {
    trajectoryTypeAdminInvokeTrajectoryAction: {
      invalidatesTags: ['TrajectoryInstanceAdmin', 'TrajectoryTypeAdmin', 'TrajectoryPlannedCommandAdmin'],
    },
    trajectoryInvokeTrajectoryInstanceAction: {
      invalidatesTags: ['Trajectory', 'TrajectoryInstanceAdmin', 'TrajectoryTypeAdmin', 'TrajectoryPlannedCommandAdmin'],
    },
    scanSubmissionSubmitSurvey: {
      invalidatesTags: [],
    },
    scanSubmissionProgressSurvey: {
      invalidatesTags: [],
    },
    scanSubmissionValidatePartialSubmissionId: {
      providesTags: [],
    },
    projectUpdateProjectOrganizationalUnitsByPublicId: {
      invalidatesTags: ['TrajectoryInstanceAdmin', 'Trajectory', 'Project', 'Projects'],
    },
    customerUpdateBasicCustomerInformationByPublicId(endpoint) {
      // inherit query from generated api
      const inheritedQueryFn = inheritQuery('updateBasicCustomerInformationByPublicId', endpoint.query);
      endpoint.query = (queryArg) => {
        const fetchArgs = inheritedQueryFn(queryArg);

        // transform body to FormData
        fetchArgs.body = serialize(queryArg.body)
        return fetchArgs;
      }
    },
    customerParticipantAddParticipantsByPublicId(endpoint) {
      // inherit query from generated api
      const inheritedQueryFn = inheritQuery('customerParticipantAddParticipantsByPublicId', endpoint.query);
      endpoint.query = (queryArg) => {
        const fetchArgs = inheritedQueryFn(queryArg);

        const formData = new FormData();
        formData.append('participantList', queryArg.body.participantList);
        fetchArgs.body = formData;
        return fetchArgs;
      }
    },
    scanAdminGetExcelExport(endpoint) {
      ignoreTags(endpoint);
      // inherit query from generated api
      const inheritedQueryFn = inheritQuery('scanAdminGetExcelExport', endpoint.query);
      endpoint.query = (queryArg) => {
        const fetchArgs = inheritedQueryFn(queryArg);
        fetchArgs.responseHandler = async (response) => {
          const filename = contentDispositionToFilename(response?.headers?.get('Content-Disposition'), 'export.xlsx');
          if (response.status === 200) {
            FileSaver.saveAs(await response.blob(), filename);
            return filename;
          }
          return response.text();
        };

        return fetchArgs;
      }
    },
    dataTransformerPresetPerformExcelTransformation(endpoint) {
      ignoreTags(endpoint);
      // inherit query from generated api
      const inheritedQueryFn = inheritQuery('dataTransformerPresetPerformExcelTransformation', endpoint.query);

      endpoint.query = (queryArg) => {
        const fetchArgs = inheritedQueryFn(queryArg);
        const formData = new FormData();
        if (queryArg.body.file) {
          formData.append('file', queryArg.body.file);
        }
        fetchArgs.body = formData;

        fetchArgs.responseHandler = async (response) => {
          const filename = contentDispositionToFilename(response?.headers?.get('Content-Disposition'), 'export.xlsx');
          if (response.status === 200) {
            FileSaver.saveAs(await response.blob(), filename);
            return filename;
          }
          return response.text();
        };

        return fetchArgs;
      }
    },
    adminUserImportUsers(endpoint) {
      // inherit query from generated api
      const inheritedQueryFn = inheritQuery('adminUserImportUsers', endpoint.query);
      endpoint.query = (queryArg) => {
        const fetchArgs = inheritedQueryFn(queryArg);

        const formData = new FormData();
        formData.append('userList', queryArg.body.userList);
        fetchArgs.body = formData;
        return fetchArgs;
      }
    },
    customerSaveBasicCustomerInformation(endpoint) {
      const inheritedQueryFn = inheritQuery('customerSaveBasicCustomerInformation', endpoint.query);
      endpoint.query = (queryArg) => {
        const fetchArgs = inheritedQueryFn(queryArg);

        const formData = new FormData();

        formData.append('name', queryArg.body.name);
        formData.append('comments', new Blob([JSON.stringify(queryArg.body.comments)], {
          type: "application/json"
        }));

        if (queryArg.body.logo) {
          formData.append('logo', queryArg.body.logo);
        }

        fetchArgs.body = formData;
        return fetchArgs;
      }
    },
    visualizationAdminGenerateVisualizations(endpoint) {
      ignoreTags(endpoint);
      // inherit query from generated api
      const inheritedQueryFn = inheritQuery('visualizationAdminGenerateVisualizations', endpoint.query);
      endpoint.query = (queryArg) => {
        const fetchArgs = inheritedQueryFn(queryArg);

        const formData = new FormData();


        formData.append('request', new Blob([JSON.stringify(queryArg.body.request)], {
          type: "application/json"
        }));

        if (queryArg.body.surveyResults) {
          formData.append('surveyResults', queryArg.body.surveyResults);
        }

        if (queryArg.body.filenameExpression) {
          formData.append('filenameExpression', queryArg.body.filenameExpression);
        }

        if (queryArg.body.iterations) {
          formData.append('iterations', new Blob([JSON.stringify(queryArg.body.iterations)], {
            type: "application/json"
          }));
        }

        if (queryArg.body.customerLogo) {
          formData.append('customerLogo', queryArg.body.customerLogo);
        }

        fetchArgs.body = formData;
        const filename = queryArg?.body?.request?.options?.filename ?? 'download.zip';
        fetchArgs.responseHandler = async (response) => {
          if (response.status === 200) {
            FileSaver.saveAs(await response.blob(), filename);
            return filename;
          }
          return response.text();
        };

        return fetchArgs;
      }
    },
    adminUserDownloadTemplate(endpoint) {
      ignoreTags(endpoint);
      // inherit query from generated api
      const inheritedQueryFn = inheritQuery('adminUserDownloadTemplate', endpoint.query);
      endpoint.query = (queryArg) => {
        const fetchArgs = inheritedQueryFn(queryArg);
        fetchArgs.responseHandler = async (response) => {
          const filename = contentDispositionToFilename(response?.headers?.get('Content-Disposition'), 'users.xlsx');
          if (response.status === 200) {
            FileSaver.saveAs(await response.blob(), filename);
            return filename;
          }
          return response.text();
        };

        return fetchArgs;
      }
    }, /*
    adminScanGetExcelExport(endpoint) {
      // inherit query from generated api
      const inheritedQueryFn = inheritQuery('adminScanGetExcelExport', endpoint.query);
      endpoint.query = (queryArg) => {
        const fetchArgs = inheritedQueryFn(queryArg);
        fetchArgs.responseHandler = async (response) => {
          const filename = contentDispositionToFilename(response?.headers?.get('Content-Disposition'), 'export.xlsx');
          if (response.status === 200) {
            FileSaver.saveAs(await response.blob(), filename);
            return filename;
          }
          return response.text();
        };

        return fetchArgs;
    }, */
    customerParticipantDownloadTemplateByPublicId(endpoint) {
      ignoreTags(endpoint);
      // inherit query from generated api
      const inheritedQueryFn = inheritQuery('customerParticipantDownloadTemplateByPublicId', endpoint.query);
      endpoint.query = (queryArg) => {
        const fetchArgs = inheritedQueryFn(queryArg);
        fetchArgs.responseHandler = async (response) => {
          const filename = contentDispositionToFilename(response?.headers?.get('Content-Disposition'), 'participants.xlsx');
          if (response.status === 200) {
            FileSaver.saveAs(await response.blob(), filename);
            return filename;
          }
          return response.text();
        };

        return fetchArgs;
      }
    },
    trajectoryDownloadTrajectoryVisualization(endpoint) {
      ignoreTags(endpoint);

      // inherit query from generated api
      const inheritedQueryFn = inheritQuery('trajectoryDownloadTrajectoryVisualization', endpoint.query);
      endpoint.query = (queryArg) => {
        const fetchArgs = inheritedQueryFn(queryArg);
        fetchArgs.responseHandler = async (response) => {

          const filename = contentDispositionToFilename(response?.headers?.get('Content-Disposition'), 'out.pdf');
          if (response.status === 200) {
            FileSaver.saveAs(await response.blob(), filename);
            return filename;
          }
          return response.text();
        };

        return fetchArgs;
      }
    },
    trajectoryDownloadAllTrajectoryVisualizationsByPath(endpoint) {
      ignoreTags(endpoint);

      // inherit query from generated api
      const inheritedQueryFn = inheritQuery('trajectoryDownloadAllTrajectoryVisualizationsByPath', endpoint.query);
      endpoint.query = (queryArg) => {
        const fetchArgs = inheritedQueryFn(queryArg);
        fetchArgs.responseHandler = async (response) => {

          const filename = contentDispositionToFilename(response?.headers?.get('Content-Disposition'), 'out.pdf');
          if (response.status === 200) {
            FileSaver.saveAs(await response.blob(), filename);
            return filename;
          }
          return response.text();
        };

        return fetchArgs;
      }
    },
    trajectoryDownloadAllTrajectoryParticipantVisualizationsByPath(endpoint) {
      ignoreTags(endpoint);

      // inherit query from generated api
      const inheritedQueryFn = inheritQuery('trajectoryDownloadAllTrajectoryParticipantVisualizationsByPath', endpoint.query);
      endpoint.query = (queryArg) => {
        const fetchArgs = inheritedQueryFn(queryArg);
        fetchArgs.responseHandler = async (response) => {

          const filename = contentDispositionToFilename(response?.headers?.get('Content-Disposition'), 'out.pdf');
          if (response.status === 200) {
            FileSaver.saveAs(await response.blob(), filename);
            return filename;
          }
          return response.text();
        };

        return fetchArgs;
      }
    },
    visualizationAdminUploadVisualisationDataset(endpoint) {
      // inherit query from generated api
      const inheritedQueryFn = inheritQuery('visualizationAdminUploadVisualisationDataset', endpoint.query);
      endpoint.query = (queryArg) => {
        const fetchArgs = inheritedQueryFn(queryArg);

        const formData = new FormData();
        formData.append('dataset', queryArg.body.dataset);
        fetchArgs.body = formData;
        return fetchArgs;
      }
    },
    visualizationAdminDownloadVisualisationDataset(endpoint) {
      ignoreTags(endpoint);

      // inherit query from generated api
      const inheritedQueryFn = inheritQuery('visualizationAdminDownloadVisualisationDataset', endpoint.query);
      endpoint.query = (queryArg) => {
        const fetchArgs = inheritedQueryFn(queryArg);
        fetchArgs.responseHandler = async (response) => {

          const filename = contentDispositionToFilename(response?.headers?.get('Content-Disposition'), 'download.xlsx');
          if (response.status === 200) {
            FileSaver.saveAs(await response.blob(), filename);
            return filename;
          }
          return response.text();
        };

        return fetchArgs;
      }
    },
    dashboardGetDashboardAttachment(endpoint) {
      ignoreTags(endpoint);

      // inherit query from generated api
      const inheritedQueryFn = inheritQuery('dashboardGetDashboardAttachment', endpoint.query);
      endpoint.query = (queryArg) => {
        const fetchArgs = inheritedQueryFn(queryArg);
        fetchArgs.responseHandler = async (response) => {
          const filename = contentDispositionToFilename(response?.headers?.get('Content-Disposition'), 'out.pdf');
          if (response.status === 200) {
            FileSaver.saveAs(await response.blob(), filename);
            return filename;
          }
          return response.text();
        };

        return fetchArgs;
      }
    }
  }
})

const contentDispositionToFilename = (contentDisposition: string | null, fallback: string) => {
  let filename = contentDisposition?.split('filename=')?.[1];
  filename = filename?.includes(';') ? filename.split(';')[0] : filename;
  return filename?.split("\"").join("") ?? fallback;
}